/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { PaperClipIcon } from "@heroicons/react/20/solid";
import { useRef } from "react";
import Parse from "parse";
import { AnalyzeText, AnalyzeImage } from "../services/ParseService";

export default function Feed({ showSuccessAlert, showDangerAlert, showLoading, responseText, responseImage }) {
  const [attachedFiles, setAttachedFiles] = useState([]);
  const inputRef = useRef(null);

  const handleFileUpload = (e) => {
    setAttachedFiles([]);
    const files = e.target.files;
    for (let i = 0; i < 1; i++) {
      setAttachedFiles((prev) => [...prev, files[i]]);
    }
  };

  const handleClick = () => {
    inputRef.current.click();
  };

  const handleRemoveFile = (file) => {
    setAttachedFiles((prev) => prev.filter((f) => f.name !== file.name));
  };

  const submitPost = async (e) => {
    e.preventDefault();
    const text = e.target.comment.value;
    const image = attachedFiles[0];
    if (text === "" && image === undefined) {
      showDangerAlert("Please add a text or image");
      return;
    } else {
      try {
        showLoading(true);
        let analyzedImage;
        if (image !== undefined) {
          //parse file upload
          let parseFile = new Parse.File(image.name, image);
          await parseFile.save();
          analyzedImage = await AnalyzeImage(parseFile.url());
          if (analyzedImage.summary.negativePointReasonList.length > 0) {
            analyzedImage.summary.score = 100 - analyzedImage.summary.score;
          }
        }
        let analyzedText = await AnalyzeText(text);

        responseText(analyzedText);
        responseImage(analyzedImage);
        showSuccessAlert();
        showLoading(false);
      } catch (error) {
        showDangerAlert(error.message);
        showLoading(false);
      }
    }
  };

  return (
    <>
      <div className="w-full mt-6 flex gap-x-3">
        <form action="#" className="relative flex-auto" onSubmit={submitPost}>
          <div className="overflow-hidden rounded-lg pb-12 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
            <label htmlFor="comment" className="sr-only">
              Add your post
            </label>
            <textarea
              rows={2}
              name="comment"
              id="comment"
              className="block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
              placeholder="Add your post..."
              defaultValue={""}
            />
          </div>

          <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
            <div className="flex items-center space-x-5">
              <div className="flex items-center">
                <button type="button" className="-m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500" onClick={handleClick}>
                  <PaperClipIcon className="h-5 w-5" aria-hidden="true" />
                  <span className="sr-only">Attach a file</span>
                  <input style={{ display: "none" }} ref={inputRef} type="file" name="img" onChange={handleFileUpload} />
                </button>
              </div>
            </div>
            <button type="submit" className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
              Post
            </button>
          </div>
        </form>
      </div>
      <div className="mt-6">
        {attachedFiles.length > 0 && (
          <div className="flex gap-x-2">
            {attachedFiles.map((file) => (
              <div key={file.name} className="flex items-center gap-x-1 bg-gray-100 rounded-md px-2 py-1 cursor-pointer" onClick={() => handleRemoveFile(file)}>
                <PaperClipIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
                <span className="text-sm font-medium text-gray-900">{file.name}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
}
