import Parse from "parse";

export const initializeParse = () => {
  Parse.serverURL = "https://parseapi.back4app.com/";
  Parse.initialize("bzUcBkgPtkd0YQfQgZZsptqbF4HBaZHGFiWGMS94", "MkylTmg7SRjJdXhSqLvBUTWE4KAIodlWAQQiIb4o");
};

export const AnalyzeText = async (text) => {
  return Parse.Cloud.run("analyzeText", { text: text });
};

export const AnalyzeImage = async (image) => {
  return Parse.Cloud.run("analyzeImage", { imageUrl: image });
};
