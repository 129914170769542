import { useEffect, useState } from "react";
import "./App.css";
import Feed from "./components/Feed";
import SuccessAlert from "./components/SuccessAlert";
import DangerAlert from "./components/DangerAlert";
import Loading from "./components/Loading";
import Review from "./components/Review";
import { initializeParse } from "./services/ParseService";

function App() {
  const [successAlert, setSuccessAlert] = useState(false);
  const [dangerAlert, setDangerAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dangerAlertMessage, setDangerAlertMessage] = useState("");
  const [responseTextAnalyze, setResponseTextAnalyze] = useState(null);
  const [responseImageAnalyze, setResponseImageAnalyze] = useState(null);
  useEffect(() => {
    initializeParse();
  }, []);

  const showSuccessAlert = () => {
    setSuccessAlert(true);
    setTimeout(() => {
      setSuccessAlert(false);
    }, 3000);
  };

  const showDangerAlert = (message) => {
    setDangerAlert(true);
    setDangerAlertMessage(message);
    setTimeout(() => {
      setDangerAlert(false);
    }, 3000);
  };

  const showLoading = (show) => {
    setLoading(show);
    if (show) {
      setResponseImageAnalyze(null);
      setResponseTextAnalyze(null);
    }
  };

  const responseText = (response) => {
    setResponseTextAnalyze(response);
  };

  const responseImage = (response) => {
    setResponseImageAnalyze(response.summary);
  };

  useEffect(() => {}, [responseTextAnalyze, responseImageAnalyze]);

  return (
    <div className="App">
      {loading && <Loading />}
      <h1 className="mb-4 mt-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl">Content Moderation</h1>
      <Feed showSuccessAlert={showSuccessAlert} showDangerAlert={showDangerAlert} responseText={responseText} responseImage={responseImage} showLoading={showLoading} />
      {successAlert && (
        <div className="alert-side">
          <SuccessAlert />
        </div>
      )}
      {dangerAlert && (
        <div className="alert-side">
          <DangerAlert dangerAlertMessage={dangerAlertMessage} />
        </div>
      )}

      <div className="review-area gap-4 mt-8">
        {responseTextAnalyze != null && <Review analyzeObject={responseTextAnalyze} title={"Text"} />}
        {responseImageAnalyze != null && <Review analyzeObject={responseImageAnalyze} title={"Image"} />}
      </div>
    </div>
  );
}

export default App;
