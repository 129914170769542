import { DocumentMagnifyingGlassIcon } from "@heroicons/react/20/solid";

// const analyzeObject = {
//   tags: ["seramik", "fayans", "işler"],
//   score: 100,
//   summary: "Her türlü seramik ve fayans işleri tarafımızca yapılmaktadır. Yeni binamıza uyguladığımız seramik işlerini paylaşıyorum. Detaylar için beni arayabilirsiniz.",
//   negativePointReasonList: [],
//   negativePointGeneralSummary: "",
// };

export default function Reviews(props) {
  const { title, analyzeObject } = props;

  return (
    <div className="w-full mt-8">
      <h2 className="sr-only">Summary</h2>
      <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
        <dl className="flex flex-wrap">
          <div className="flex-auto pl-6 pt-6">
            <dt className="text-sm font-semibold leading-6 text-gray-900">{title} - Analyze</dt>
            {analyzeObject.score < 70 && <dd className="mt-1 text-base font-semibold leading-6 text-red-600">Score: {analyzeObject.score}</dd>}
            {analyzeObject.score >= 70 && analyzeObject.score < 85 && <dd className="mt-1 text-base font-semibold leading-6 text-yellow-600">{analyzeObject.score}</dd>}
            {analyzeObject.score >= 85 && <dd className="mt-1 text-base font-semibold leading-6 text-green-600">{analyzeObject.score}</dd>}
          </div>
          <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
            <dt className="flex-none">
              <span className="sr-only">Summary</span>
              <DocumentMagnifyingGlassIcon className="h-6 w-5 text-gray-400" aria-hidden="true" />
            </dt>
            <dd className="text-sm leading-6 text-gray-500">{analyzeObject.summary}</dd>
          </div>
          {/* tags */}
          <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
            {analyzeObject.tags.map((tag) => (
              <div className="flex justify-center items-center m-1 px-2 py-1 border border-gray-300 rounded-full bg-gray-200 text-base text-gray-700 font-medium">
                <div className="flex-initial max-w-full leading-none text-xs font-normal">
                  <span className="inline-block">{tag}</span>
                </div>
              </div>
            ))}
          </div>
        </dl>
        <div className="mt-6 border-t border-gray-900/5 px-6 py-6"></div>
      </div>
    </div>
  );
}
